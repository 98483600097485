<template>
  <v-container
    :style="{
      maxWidth: '1000px',
    }"
  >
    <div class="d-flex justify-space-between">
      <text-btn
        small
        icon="mdi-arrow-left"
        @click="$emit('tab-selected', 'Main')"
        >Вернуться на главную</text-btn
      >
    </div>
    <h3 class="text-center flex-grow-1 mb-5">
      Материалы для подготовки к центру оценки и развития
    </h3>
    <p class="text-justify">
      На этой странице Вы можете ознакомиться с организационной информацией и
      материалами для подготовки к центру оценки и развития (ассессмент-центру).
      Обязательно <b>заранее</b> проверьте технические доступы в систему, и при
      появлении вопросов/ошибок обратитесь к Вашему администратору.
    </p>
    <br />
    <v-row>
      <v-col v-for="doc in session.documents" :key="doc.id" cols="6">
        <a :href="validLink(doc.link)" target="_blank">
          <v-card elevation="0" class="card-material">
            <p
              class="ma-auto text-center text--primary pa-0 card-material_title"
            >
              {{ doc.name }}
            </p>
          </v-card>
        </a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { computed, onMounted } from "@vue/composition-api";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const session = computed(
      () => store.getters.getParticipantAssessmentSession
    );

    const validLink = link => {
      const testedLink = /^https?:\/\//.test(link);
      return testedLink ? link : `http://${link}`;
    };

    onMounted(() => localStorage.setItem("lastTab", "Preparing"));

    return {
      session,
      validLink,
    };
  },
};
</script>
